body {
  margin: 0;
  width: 100vw;
  height: 100vh;
}

#root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
